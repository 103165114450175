import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components'
import { kebabCase } from 'lodash';
import SEO from '../components/seo';

import Layout from './layout';
import Header from './header';
import Container from './Container';
import Hero from '../components/Hero'
import Heading from '../components/Heading'
import Box from '../components/Box'
import Text from '../components/Text'
import Flex from '../components/Flex'
import Card from '../components/Card'
import Grid from '../components/Grid'
import TagList from '../components/TagList'
import TagListItem from '../components/TagListItem'
import ShowAll from '../components/ShowAll'

const MainGrid = styled.div`
  @media only screen and (min-width: 40em) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
`

const PositionType = ({ pageContext, data }) => {
    const { position } = pageContext;
    //const allTags = data.allMarkdownRemark.group;
    const { edges, totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} “${position}” job${
      totalCount === 1 ? '' : 's'
    }`;
    return (
        <Layout>
            <SEO title={`${position} Jobs`} />
            <Header />
            <Hero title={tagHeader} />
            <Container as="main">
                <MainGrid>

                    <Box as="section">
                        <div css="border-bottom: solid 2px #ebebeb">
                            <Flex justifyContent="space-between" alignItems="center" mb="3">
                                <Heading as="h2" fontSize="3" fontWeight="500" pb="0">{tagHeader}</Heading>
                                <ShowAll><Link to="/tags">Show filters</Link></ShowAll>
                            </Flex>
                        </div>
                        <Box as="ul" p="0">
                            {edges.map(({ node }) => {
                            const { title, location, image, company, tags, experience_level, position_type } = node.frontmatter;
                            const { slug } = node.frontmatter;
                            return (
                                <li key={slug} css="list-style: none;">
                                    <Card key={slug} mb="4">
                                        <Grid gridTemplateColumns={['1fr', '200px 4fr']} gridGap={['3', '4']}>
                                            <Box mt="3" mb="3" ml="3">
                                                <Link 
                                                    to={`/${slug}/`} 
                                                    style={{
                                                        textDecoration: 'none',
                                                        display: 'block',
                                                    }}>
                                                    <img src={`${image}`} alt={company}/>
                                                </Link>
                                            </Box>
                                            <Box p="3">
                                                <Heading as="h2" fontSize="4" textDecoration="none" mb="1" p="0">
                                                    <Link to={`/${slug}/`}>{title}</Link>
                                                </Heading>
                                                <Text as="p" fontWeight="400">
                                                    <Link to={`/jobs-at-${kebabCase(company)}/`}>
                                                        {company}
                                                    </Link>
                                                </Text>
                                                <Box mt="3">
                                                    {tags ? (
                                                        <div className="TagContainer">
                                                            <TagList>
                                                                {tags.map(tag => (
                                                                <TagListItem key={tag + `tag`}>
                                                                    <Link to={`/jobs-in-${kebabCase(tag)}/`}>{tag}</Link>
                                                                </TagListItem>
                                                                ))}
                                                            </TagList>
                                                            {location ? ( 
                                                            <TagListItem as="p">
                                                                <Link to={`/jobs-in-${kebabCase(location)}/`}>
                                                                    {location}
                                                                </Link>
                                                            </TagListItem>                                       
                                                            ) : null}
                                                            {experience_level ? ( 
                                                            <TagListItem as="p">
                                                                <Link to={`/${kebabCase(experience_level )}-jobs/`}>
                                                                    {experience_level }
                                                                </Link>
                                                            </TagListItem>                                       
                                                            ) : null}
                                                            {position_type ? ( 
                                                            <TagListItem as="p">
                                                                <Link to={`/${kebabCase(position_type )}-jobs/`}>
                                                                    {position_type }
                                                                </Link>
                                                            </TagListItem>                                       
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Card>
                                </li>
                            );
                            })}
                        </Box>
                    </Box>
                </MainGrid>
            </Container>
        </Layout>
    );
  };
  
  export default PositionType;
  
  export const pageQuery = graphql`
    query($position: String, $currentDate: Date!) {
      allMarkdownRemark(
        limit: 2000
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { position_type: { in: [$position] }, published: { eq: true }, deadline: { gte: $currentDate } } }
      ) {
        group(field: frontmatter___position_type) {
        fieldValue
        totalCount
        }
        totalCount
        edges {
          node {
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              slug
              location
              experience_level
              position_type
              image
              company
              tags
            }
          }
        }
      }
    }
  `;